import { green, orange } from 'src/styles/colors';
import styled, { css } from 'styled-components';

type ContainerStyles = {
  isPj?: boolean;
  customDotColor?: string;
};

export const Container = styled.div<ContainerStyles>`
  .react-multi-carousel-track {
    padding: 30px 0 50px;
  }

  /* Arrows */
  .react-multiple-carousel__arrow {
    bottom: -8px;
    outline: none;
    z-index: 1;

    &::before {
      content: none;
    }
  }

  /* Dots */
  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button {
        ${(props: ContainerStyles) => props.isPj && css`
          background-color: ${(props: ContainerStyles) => props.isPj ? green[500] : orange.base};
        `}

        ${(props: ContainerStyles) => props.customDotColor && css`
        background-color: ${({ customDotColor }: ContainerStyles) =>
          customDotColor ? customDotColor : orange.base};
        `}
        
        border: none;
        opacity: 0.5;
        width: 8px;
        height: 4px;
        border-radius: 2px;
      }

      &--active {
        button {
          width: 16px;
          opacity: 1;
        }
      }
    }
  }
`
