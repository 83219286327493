import { breakpoints } from 'src/styles/breakpoints';
import { grayscale } from 'src/styles/colors';
import styled from 'styled-components';

type InputProps = {
  color?: string;
  background?: string;
  placeholderColor?: string;
}

type CircleProps ={
  background?: string;
}

type SummaryProps = {
  borderColor?: string;
  active?: boolean;
}

export const Input = styled.input`
  width: 100%;
  height: 100%;
  color: ${(props: InputProps) => props.color || grayscale[500]};
  background-color: ${(props: InputProps) => props.background || grayscale[100]};
  border: none;
  font-weight: 600;
  padding: 16px;
  font-family: "Inter", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;

  &:focus{
    outline: none;
  }

  &::placeholder{
    color:${(props: InputProps) => props.placeholderColor || grayscale[300]} ;
  }
`

export const Search = styled.div`
  position: relative;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.lg}){
    margin-bottom: 38px;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    right: 16px;
    fill: transparent;
  }
`

export const Circle = styled.div`
  width: 32px;
  height: 32px;
  background: ${(props: CircleProps) => props.background || 'none'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 32px;
  min-width: 32px;

  svg {
    fill: transparent;
  }
`

export const Summary = styled.div`
  border-bottom: 1px solid ${(props: SummaryProps) => props.borderColor || grayscale[200]};

  .header {
    ${(props: SummaryProps) => !props.active && 'min-height: 76px' };
    padding-bottom: 8px;
    cursor: pointer;
  }
`

export const SummaryCol = styled.div`
  margin-top: 24px;

  @media (min-width: ${breakpoints.md}){
    margin-top: 40px;
  }
  @media (min-width: ${breakpoints.lg}){
    margin-top: 48px;
  }
`

export const SummaryDetails = styled.div`
  margin-top: 8px;
  padding-bottom: 24px;

  @media (min-width: ${breakpoints.md}){
    margin-top: 14px;
  }

  ul {
    margin-left: 15px;
  }
`

export const Button = styled.button`
  border:none;
  background: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform:translateY(-50%);

  &:focus {
    outline: none;
  }
`
