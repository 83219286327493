import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import React from 'react'

type RightArrowProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  customColor?: string;
}

function RightArrow ({ onClick, customColor }: RightArrowProps) {
  return (
    <button
      onClick={onClick} type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color={customColor || 'orange--base'} />
    </button>
  )
}

export default RightArrow
