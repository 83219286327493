import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section } from './style'
const Bussola = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pratica-mockup-bussula/image.webp'

const OrganizeSuasContas = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const scrollToTop = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Section
      className='py-5'
      role='img'
      aria-label='Tela do Super App do Inter mostrando gráfico de entradas e saídas de dinheir.'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center cont-bussola'>
            <img alt='Bússola' src={Bussola} />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-40 fs-md-40 fs-xl-46 lh-40 lh-xl-50 text-grayscale--500 mt-3 mt-md-0'>
              Organize suas contas
            </h2>
            <p className='fs-16 fs-xl-18 lh-20 lh-xl-22 text-grayscale--400'>
              Com a Bússola do Inter, você faz seu planejamento financeiro controlando seus gastos e economias sem pagar nada por isso.
            </p>
            <a
              target='_blank'
              href='#'
              className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-2 mt-md-3'
              onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                scrollToTop(event)
                sendDatalayerEvent({
                  section: 'dobra_05',
                  element_action: 'click button',
                  element_name: 'Quero organizar agora!',
                  section_name: 'Organize suas contas - Bussola',
                })
              }}
            >
              Quero organizar agora!
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OrganizeSuasContas
