import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import { orange } from '../../../../styles/colors'

export const Section = styled.section`

  @media ${device.tablet} {
    height: 360px;
  }

  @media ${device.desktopXL} {
    height: 514px;
  }

  @media ${device.desktopXXXL} {
    height: 524px;
  }

  h2 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  .bg {
    background-repeat: no-repeat;
    background-size: cover;
    height: 214px;

    &.bgblack {
      background-color: green;
    }

    @media ${device.desktopXL} {
      height: 264px;
    }

    @media ${device.desktopXXXL} {
      height: 373px;
    }

    .content {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .content-text {
    width: 100%;
    height: 100%;
    cursor: auto;

    &.bg-hover {
      background-color: black;
      opacity: 0.8;
      border-radius: 19px;
    }

    .description {
      position: relative;
      top: 70px;

      @media ${device.tablet} {
        height: 100%;
        display: flex;
        align-items: center;
        top: 0;
      }
    }
  }

  .react-multi-carousel-dot {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra};
    }
  }

  .react-multiple-carousel__arrow--left {
    @media ${device.tablet} {
      left: 10px;
    }
  }

  .react-multiple-carousel__arrow--right {
    @media ${device.tablet} {
      right: -3px;
    }
    @media ${device.desktopXL} {
      right: 7px;
    }
  }
`

export const Carousel = styled.div`
  @media ${device.tablet} {
    position: absolute;
    right: -12px;
  }

  @media ${device.desktopXL} {
    right: 0;
  }
`
