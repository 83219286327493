import React, { ReactNode } from 'react'
import { Modal } from 'src/components/Modal'
import YoutubeVideo from '../YoutubeVideo'
import useDomReady from '../../hooks/window/useDomReady'

type OpenVideoProps = {
  link: string;
  children: ReactNode;
  onClick?: Function;
  styles?: string;
};

function OpenVideo ({ children, link, onClick, styles }: OpenVideoProps) {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false)

  function handleClose () {
    setIsModalOpen(false)
  }

  function handleOpen () {
    setIsModalOpen(true)
    if (onClick) {
      onClick()
    }
  }

  return (
    <>
      <div onClick={() => handleOpen()} className={styles}>
        {children}
      </div>
      {domReady && (
        <Modal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          locationToRender={window.document.body}
        >
          <div className='modal'>
            <div className='modal__backdrop' onClick={() => handleClose()} />
            <div className='modal__content'>
              <div className='modal__content__close_button' onClick={() => handleClose()}>
                X
              </div>
              <YoutubeVideo src={link} width='100%' height='100%' />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

OpenVideo.defaultProps = {
  link: '',
  children: <></>,
}

export default OpenVideo
